<template>
  <div class="view-account">
    <div class="container py-5">
      <h4 class="fw-normal text-center mb-5">Minha conta</h4>

      <form class="row justify-content-center" @submit.prevent="submit">
        <div class="col-lg-2 col-md-4 col-8 mb-4">
          <image-input @pick="pickFile" :url="avatar" />
        </div>
        <div class="col-12"></div>
        <div class="col-lg-4 col-md-8">
          <form-group label="E-mail" v-model="form.email" id="email" readonly />
          <form-group
            v-model="form.name"
            :errors="errors.name"
            id="name"
            label="Nome"
          />
          <form-group
            v-model="form.phone"
            :errors="errors.phone"
            id="phone"
            label="Telefone"
            type="tel"
          />

          <button
            type="submit"
            class="btn btn-primary w-100"
            :disabled="loading"
          >
            <loading :show="loading">Salvar</loading>
          </button>

          <div class="text-center mt-4">
            <button type="button" class="btn text-light" @click="dialog = true">
              Alterar senha
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <el-dialog v-model="dialog" title="Alterar senha">
    <change-password @close="dialog = false" />
  </el-dialog>
</template>

<script>
import { formFields } from "@/functions";
import get from "lodash/get";
import ChangePassword from "@/components/accounts/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    const user = get(this.$store, "state.accounts.user", {});

    return {
      dialog: false,
      avatar: get(user, "avatar"),
      newAvatar: false,
      ...formFields(["email", "name", "phone"], user),
    };
  },
  methods: {
    pickFile(file) {
      this.newAvatar = true;
      this.avatar = file;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;

      const data = {
        ...this.form,
      };
      if (this.newAvatar) {
        data.avatar = this.avatar;
      }

      this.$store
        .dispatch("accounts/put", data)
        .then(() => {
          this.$message.success("Alterações salvas com sucesso");
        })
        .catch((error) => {
          this.errors = error.response.data;
          if (error.response.data.message) {
            this.$message.error(error.response.data.message[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
