<template>
  <form @submit.prevent="submit" class="accounts-change-password">
    <form-group
      v-model="form.password_old"
      :errors="errors.password_old"
      id="password_old"
      label="Senha antiga"
      type="password"
    />
    <form-group
      v-model="form.password"
      :errors="errors.password"
      id="password"
      label="Nova senha"
      type="password"
    />

    <div class="d-flex align-items-center justify-content-between">
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-outline-secondary"
      >
        Cancelar
      </button>

      <button type="submit" class="btn btn-primary" :disabled="loading">
        <loading :show="loading">Salvar</loading>
      </button>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions";

export default {
  data() {
    return {
      ...formFields(["password", "password_old"]),
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch("accounts/changePassword", this.form)
        .then(() => {
          this.$message.success("Alterações salvas com sucesso");
          this.$emit("close");
        })
        .catch((error) => {
          this.errors = error.response.data;
          if (error.response.data.message) {
            this.$message.error(error.response.data.message[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
